import Vue from "vue";
import VueRouter from "vue-router";

// 解决跳转重复路由报错问题的代码
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch((err) => {});
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/home.vue"),
    meta: {
      // 页面标题title
      title: "NETWORK NEXUS",
    },
  },
  {
    path: "/conditions",
    name: "Conditions",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/conditions/conditions.vue"
      ),
    meta: {
      // 页面标题title
      title: "NETWORK NEXUS",
    },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../views/terms/terms.vue"),
    meta: {
      title: "NETWORK NEXUS",
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("../views/privacy/privacy.vue"),
    meta: {
      title: "NETWORK NEXUS",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
